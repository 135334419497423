import React from "react";
import { Outlet } from "react-router-dom";
const Breadcumb = React.lazy(() => import('../components/comman/Breadcumb'))
const Header = React.lazy(() => import('../components/header/Header'))
const Footer = React.lazy(() => import('../components/footer/Footer'))
const Brandarea = React.lazy(() => import('../components/comman/Brandarea'))
const Menu = React.lazy(()=>import('../components/menu/Menu'))

const Layouthome = () => {
  return (
    <div>
      <div className="header-area inner-style" id="sticky-header">
      <Header />
      </div>
      <Menu />
      <Breadcumb />
      <Outlet />
      <Brandarea />
      <Footer />
    </div>
  );
}

export default Layouthome