import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layoutinner from './layout/Layoutinner';
const Loader = React.lazy(()=>import('./components/loder/Loader'))
const Layout = React.lazy(() => import('./layout/Layout'));
const Home = React.lazy(() => import('./pages/home/Home'));
const About = React.lazy(()=>import('./pages/about/About'));
const Contact = React.lazy(()=>import('./pages/contact/Contact'))
const Privacypolicy = React.lazy(()=>import('./pages/privacypolicy/PrivacyPolicy'))
const Digitalmarketing = React.lazy(()=>import('./pages/digitalmarketing/DigitalMarketing'))
const Mobileapplication = React.lazy(()=>import('./pages/mobileapplication/MobileApplication'))
const Ourcompany = React.lazy(()=>import('./pages/ourcompany/OurCompany'))
const Companyhistory = React.lazy(()=>import('./pages/companyhistory/CompanyHistory'))
const Ourteam = React.lazy(()=>import('./pages/ourteam/OurTeam'))
const Ourpartners = React.lazy(()=>import('./pages/ourpartners/OurPartners'))
const Uiux = React.lazy(()=>import('./pages/uiux/Uiux'))
const Webapplication = React.lazy(()=>import('./pages/webapplication/WebApplication'))
const Faqs = React.lazy(()=>import('./pages/faqs/Faqs'))
const Blogs = React.lazy(()=>import('./pages/blogs/Blogs'))
const BlogDetails = React.lazy(()=>import('./pages/blogs/BlogDetails'))
const NotFound = React.lazy(() => import('./pages/404/NotFound'));

const App = () => {
  return (
    <div>      
      <Router>
        <Suspense fallback={<Loader />}>
        <Routes>
          {/*Layout home page. */}
          <Route element={<Layout />}>
            <Route path='/' element={<Home /> } />
          </Route>
          {/* Layout for About, Contact, Services, etc. */}
          <Route element={<Layoutinner />}>
            <Route path="about" element={<About />} />
            <Route path="contact-us" element={<Contact />} />
            <Route path="privacy-policy" element={<Privacypolicy />} />
            <Route path="services/digital-marketing" element={<Digitalmarketing />} />
            <Route path="services/mobile-application" element={<Mobileapplication />} />
            <Route path="about/our-company" element={<Ourcompany />} />
            <Route path="about/company-history" element={<Companyhistory />} />
            <Route path="about/our-team" element={<Ourteam />} />
            <Route path="about/our-partners" element={<Ourpartners />} />
            <Route path="services/ui-ux" element={<Uiux />} />
            <Route path="services/web-application" element={<Webapplication />} />
            <Route path="faqs" element={<Faqs />} />
            <Route path="blogs" element={<Blogs />} />
            <Route path="blogs/:slug" element={<BlogDetails />} />
          </Route>
          {/* Catch-all for undefined routes */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
